.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  width: 146px;
  margin: auto;
  font-size: 14px;
  background-color: #fcf8db;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 800px rgba(99, 100, 102, 0.2);
  z-index: 10;
  position: absolute;
  left: 29%;
}
/* For margins around the arrow towards the carousel */
.dropdownArrow {
  // margin-top: 8px;
  margin-bottom: 5px;
  cursor: pointer;
}

.dropdownList {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.dropdownList:hover {
  background-color: #fcb817;
  color: white;
}

.arrowUp {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
}

.dropdownVisibility {
  visibility: hidden;
}
