body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* @font-face {
    font-family: 'Arial Rounded MT Bold';
    src: local('Arial Bold'), url(./fonts/Arial Rounded MT Bold.tff) format('truetype');
    font-family: 'Arial Rounded MT';
    src: local('Arial'), url(./fonts/Arial_Rounded_MT.tff) format('truetype');
  } */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.landing {
  height: 100%;
  width: auto;
  max-width: 375px;
  max-height: 812px;
}
