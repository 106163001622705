.ql-container {
  background-color: #fcf8db;
}
.ql-toolbar.ql-snow {
  border-width: 0;
}
.ql-container.ql-snow {
  border-width: 0;
  border-radius: 6px;
}
